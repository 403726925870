import React from 'react';
import { Link } from 'react-router-dom';
import { FaFacebookF, FaLinkedin, FaInstagram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
//import ServiceData from "../../data/service/ServiceMain.json";
//import ProjectData from "../../data/project/ProjectData.json";

//import { slugify } from '../../utils';

//const getProjectData = ProjectData;

const FooterOne = ({parentClass}) => {
    
    return (
        <footer className={`footer-area ${parentClass}`}>
            <div className="container">
                <div className="footer-top">
                    <div className="footer-social-link">
                        <ul className="list-unstyled">
                            <li><Link to="https://twitter.com/nexistdigital"><FaXTwitter /></Link></li>
                            <li><Link to="https://www.linkedin.com/company/nexist-srl/"><FaLinkedin /></Link></li>
                            <li><Link to="https://www.instagram.com/nexistdigital/"><FaInstagram /></Link></li>
                            <li><Link to="https://www.facebook.com/nexistdigital"><FaFacebookF /></Link></li>
                        </ul>
                    </div>
                </div>
                <div className="footer-main">
                    <div className="row">
                        <div className="col-xl-5 col-lg-4">
                            <div className="footer-widget border-end">
                                <div className="footer-newsletter">
                                    <h2 className="title" style={{ fontSize: '40px'}}>Request a demo!</h2>
                                    <p>Do you want to try our technology? Write to us and we will contact you as soon as possible.</p>
                                    <Link to="mailto:info@nexist.it">
                                        <button className="subscribe-btn">Contact Us &rarr;</button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-7 col-lg-8">
                            <div className="row">
                                <div className="col-sm-5">
                                    <div className="footer-widget">
                                        <h6 className="widget-title">Our Partners</h6>
                                        <div className="footer-menu-link">
                                            <ul className="list-unstyled">
                                                <img className="d-block w-50" src="/images/testimonial/the-hive.png" alt="the-hive" />
                                                <img className="d-block w-50 mt-4" src="/images/testimonial/sida.png" alt="sida" />
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-7">
                                    <div className="footer-widget">
                                        <h6 className="widget-title">Other Info</h6>
                                        <small>The NEXIST project is co-financed by the European Union and the Marche Region through the "Start&Innova" programme. Grant awarded € 40,000.</small>
                                        <img className="mt-2" src="/images/testimonial/PR_FESR21-27.jpg" alt="FESR" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom"> 
                    <div className="row">
                        <div className="col-md-6">
                            <div className="footer-copyright">
                                <span className="copyright-text">© <strong>NEXIST srl</strong> - P.I. 02969570429</span>
                            </div>
                        </div>
                        <div className="col-md-6">
                            {/*<div className="footer-bottom-link">
                                <ul className="list-unstyled">
                                    <li><Link to={process.env.PUBLIC_URL + "/privacy-policy"}>Privacy Policy</Link></li>
                                    <li><Link to={process.env.PUBLIC_URL + "/terms-use"}>Terms of Use</Link></li>
                                </ul>
                            </div>*/}
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default FooterOne;