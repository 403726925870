import React from 'react';
import { Link } from 'react-router-dom';
import Tilty from 'react-tilty';


const BannerFour = () => {
    return (
        <div className="banner banner-style-4">
            <div className="container">
                <div className="banner-content">
                    <h1 className="title">The Digital Product Passport ecosystem</h1>
                    <p>Empowering brands and customers through the creation of a digital twin of their physical products.</p>
                    <div>
                        <Link to="mailto:info@nexist.it" className="axil-btn btn-borderd btn-banner-reset">Request a demo</Link>
                    </div>
                </div>
                <div className="banner-thumbnail">
                    <div className="large-thumb">
                        <Tilty perspective={3000}>
                            <img className="max-w-md" src={process.env.PUBLIC_URL + "/images/banner/nexist_dpp.png"} alt="Shape" />
                        </Tilty>
                    </div>
                </div>
                <div className="banner-social">
                    <div className="border-line" />
                    <img src={process.env.PUBLIC_URL + "/images/favicon.png"} className="nexist-logo" alt="Logo" /> Empower Authenticity
                </div>
            </div>
            <ul className="list-unstyled shape-group-19">
                <li className="shape shape-1">
                    <img src={process.env.PUBLIC_URL + "/images/others/bubble-29.png"} alt="Bubble" />
                </li>
                <li className="shape shape-2">
                    <img src={process.env.PUBLIC_URL + "/images/others/line-7.png"} alt="Bubble" />
                </li>
            </ul>
        </div>
    )
}

export default BannerFour;