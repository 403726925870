import React from 'react';
import { Link } from 'react-router-dom';
//import { FaAngleDown } from "react-icons/fa";
//import ServiceData from "../../data/service/ServiceMain.json";
//import ProjectData from "../../data/project/ProjectData.json";
//import { slugify } from '../../utils';


const Nav = () => {
    return (
        <nav className="mainmenu-nav">
            <ul className="mainmenu" style={{ justifyContent: 'right' }}>
                {/*<li className="menu-item">
                    <Link to="#">Digital Product Passport</Link>
                </li>*/}
                {/*<li className="menu-item-has-children">
                    <Link to="#">Services <FaAngleDown /></Link>
                    <ul className="axil-submenu">
                        {ServiceData.map((data) => (
                            <li><Link to={process.env.PUBLIC_URL + `/service-details/${slugify(data.title)}`}>{data.title}</Link></li>
                        ))}
                    </ul>
                </li>*/}
                {/*<li className="menu-item-has-children">
                    <Link to="#">Industries <FaAngleDown /></Link>
                    <ul className="axil-submenu">
                        {ProjectData.map((data) => (
                            <li><Link to={process.env.PUBLIC_URL + `/project-details/${slugify(data.title)}`}>{data.title}</Link></li>
                        ))}
                    </ul>
                </li>*/}
                {/*<li><Link to={process.env.PUBLIC_URL + "/blog-grid"}>Resources</Link></li>*/}
                {/*<li><Link to={process.env.PUBLIC_URL + "/about-us"}>About Us</Link></li>*/}
                <li><Link to="mailto:info@nexist.it">Contact</Link></li>
            </ul>
        </nav>
    )
}

export default Nav;